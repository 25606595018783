
export class MatchStrengthBreakdown {
    constructor(public baseScoreOutOfFive: number,
        public modifiersList: {[key: string] : number}) {
    }

    get scoreOutOfFive() {
        let result = this.baseScoreOutOfFive;
        for(let x in this.modifiersList) {
            result += this.modifiersList[x]
        }
        return result;
    }
}