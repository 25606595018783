import { Paint } from "./Paint";
import { PaintCodeSetDto } from "./PaintCodeSetDto";

export class PaintCodeSet {

    constructor(public brandName: string, public setName: string, public paints: Paint[]) {
    }

    get slug() {
        return this.brandNameSlug + '-' + this.setName.toLowerCase().replace(' ', '-');
    }

    get brandNameSlug() {
        return this.brandName.toLowerCase().replace(' ', '-');
    }

    get route() {
        return `/paint-conversion-chart/${this.brandedSlugWithoutGeneral.toLowerCase()}/`;
    }

    get brandedSlugWithoutGeneral() {   
        return this.brandName + (this.setName.toLowerCase() == 'general' ? '' : '-' + this.setName);
    }

    // Some code sets (like RAL) are only used as the source not the destination
    get canBeMatchedTo() {
        return !['ral', 'fs', 'rlm'].includes(this.brandNameSlug);
    }

    static parse(codeSetName: string) {
        const sects = codeSetName.split('-');
        if (sects.length !== 2) throw new Error('Invalid branded paint code set name: ' + codeSetName);
        return new PaintCodeSet(sects[0], sects[1], []);
    }

    get fullName() {
        return `${this.brandName}-${this.setName}`;
    }

    toDto(): PaintCodeSetDto {
        return {
            brandName: this.brandName,
            setName: this.setName
        }
    }

    static fromDto(dto: PaintCodeSetDto): PaintCodeSet {
        return new PaintCodeSet(dto.brandName, dto.setName, []);
    }
}