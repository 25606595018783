import { PaintCodeSet, PaintDto, Paint } from "@Models";

const savedUserBasketKey = "savedUserBasket";

export function loadStoredBasket() {
    if (typeof window === 'undefined') return [];
    const savedUserBasket = localStorage.getItem(savedUserBasketKey) || '[]';
    try
    {
        const deserialised = JSON.parse(savedUserBasket) as PaintDto[];
        return deserialised.map(Paint.fromDto);
    }
    catch(ex)
    {
        console.error(ex);
        deleteStoredBasket();
        return [];
    }
}

export function addPaintToStoredBasket(paint: Paint) {
    if (!paint.codeSet) throw new Error('Cannot add a paint with out a codeset to the basket');
    const paints = loadStoredBasket();
    if (!paints.some(x => x.getUniquePaintCode() === paint.getUniquePaintCode())) {
        paints.push(paint);
    }
    saveStoredBasket(paints);
}


export function removeFromStoredBasket(paint: Paint) {
    if (!paint.codeSet) throw new Error('Cannot remove a basket paint with out a codeset');
    const paints = loadStoredBasket();
    const index = paints.findIndex(x => x.codeSet.fullName === paint.codeSet.fullName && x.productCode === paint.productCode);
    if (index >= 0) {
        paints.splice(index, 1);
    }
    saveStoredBasket(paints);
}

export function deleteStoredBasket() {
    if (typeof window === 'undefined') return;
    localStorage.removeItem(savedUserBasketKey);
}

export function saveStoredBasket(paints: Paint[]) {
    const dtos = paints.map(x => x.toDto());
    localStorage.setItem(savedUserBasketKey, JSON.stringify(dtos));
}