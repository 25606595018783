const langKey = "lang";

export function loadPreferredLanguage() {
    if (typeof window === 'undefined') return undefined;
    const langSetting = localStorage.getItem(langKey);
    return langSetting;
}

export function setPreferredLanguage(spellingKey: string) {
    if (typeof window === 'undefined') return;
    localStorage.setItem("lang", spellingKey);
}
