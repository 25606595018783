import { ConversionChart, ConversionChartRow, PaintCodeSet } from "@Models";
import Axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { deleteDB } from 'idb';
import axios from 'axios';
import { mapToPaint } from "../graphql/graphql-mappers";
import * as Sentry from '@sentry/browser';

async function deleteDatabase() {
    try {
        await deleteDB('modelshade', {
            blocked() {

            },
        });
    } catch (e) {
        console.error(e);
    }
}

async function downloadDataFile(): Promise<string[]> {
    var axiosRequestConfig: AxiosRequestConfig = {};
    const axiosResult = await Axios.get(`/ancil.ddt`, axiosRequestConfig);
    const csvData = axiosResult.data;
    var csvLines = csvData.split(/\r\n|\n/) as string[];
    return csvLines;
}

export async function downloadCharts(): Promise<{ [brandedCodeSet: string]: ConversionChart }> {
    const results: { [brandedCodeSet: string]: ConversionChart } = {};
    await deleteDatabase();
    const dataFileLines = await downloadDataFile();
    let i = 0;
    while (i < dataFileLines.length) {
        const sectionTitle = dataFileLines[i++].split('-');
        if (sectionTitle[0] !== '#chart') throw new Error('Expected the next line to be #chart but was: ' + dataFileLines[i - 1]);
        const chartForBrand = sectionTitle[1];
        const chartForCodeSet = sectionTitle[2];
        const sourceBrandedCodeSet = `${chartForBrand}-${chartForCodeSet}`;
        const headers = dataFileLines[i++].split(',');
        if (headers[0] !== sourceBrandedCodeSet) throw new Error(`Invalid chart ${dataFileLines[i - 1]} the first colum must be the source set`);
        const chart: ConversionChart = {
            sourceCodeSet: sourceBrandedCodeSet,
            sourcePaints: {}
        };
        while (i < dataFileLines.length && !dataFileLines[i].startsWith('#')) {
            const fields = dataFileLines[i++].split(',');
            const row: ConversionChartRow = {
                productCode: fields[0],
                matches: []
            };
            for (let j = 1; j < headers.length; j++) {
                if (!fields[j] || fields[j].length == 0) continue;
                const destCodeSet = headers[j];
                const destCodes = fields[j].split('|');
                for (let destCode of destCodes) {
                    row.matches.push({
                        codeSet: destCodeSet,
                        productCode: destCode
                    });
                }
            }
            chart.sourcePaints[row.productCode] = row;
        }
        results[sourceBrandedCodeSet] = chart;
    }
    return results;
}

export async function downloadAllSourcePaints(allCodeSetNames: string[]): Promise<PaintCodeSet[]> {
    const promises: Promise<AxiosResponse<any>>[] = [];
    for (let codeSetName of allCodeSetNames) {
        const url = codeSetName.endsWith('-General') ? codeSetName.substring(0, codeSetName.length - '-General'.length) : codeSetName;

        const promise = axios.get(`/page-data/paint-conversion-chart/${url.toLowerCase()}/page-data.json`, {});
        promises.push(promise);
    }
    const responses = await Promise.all(promises);
    const results: PaintCodeSet[] = [];
    let done = 0;
    for (let response of responses) {
        if (!response.data.result.data) {
            Sentry.withScope(scope => {
                scope.setExtra('responseStatus', response.status);
                scope.setExtra('responseData', response.data);
                Sentry.captureException(new Error("Failed to initially download myFullPaintList data for " + response.config.url + ", " + done + " were done ok"));
            });
            const tryAgain = await axios.get(response.config.url, {});
            if (!tryAgain.data.result.data) {
                Sentry.withScope(scope => {
                    scope.setExtra('responseStatus', tryAgain.status);
                    scope.setExtra('responseData', tryAgain.data);
                    Sentry.captureException(new Error("Failed to download myFullPaintList SECOND TIME for " + response.config.url));
                });
                continue;
            } else {
                const sourceCodeSet = PaintCodeSet.parse(tryAgain.data.result.data.myFullPaintList.edges[0].node.name);
                const paints = tryAgain.data.result.data.myFullPaintList.edges[0].node.childMultiCsvFile.items.map(x => mapToPaint(sourceCodeSet, x));
                sourceCodeSet.paints = paints;
                results.push(sourceCodeSet);
                done++;
                continue;
            }
        }
        const sourceCodeSet = PaintCodeSet.parse(response.data.result.data.myFullPaintList.edges[0].node.name);
        const paints = response.data.result.data.myFullPaintList.edges[0].node.childMultiCsvFile.items.map(x => mapToPaint(sourceCodeSet, x));
        sourceCodeSet.paints = paints;
        results.push(sourceCodeSet);
        done++;
    }
    return results;
}
