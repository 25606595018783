import { Mdx, MultiCsvFileItems, SiteSiteMetadata, SiteSiteMetadataSocial } from "@GraphQLModels";
import { isNullOrEmpty } from "@jcharlesworthuk/your-mum-core/dist/functions";
import { Article, ISocialPage, TwitterPage, WebsiteInformation, HowToData, HowToStep } from "@jcharlesworthuk/your-mum-frontend/models";
import { Colour, PaintCodeSet, PaintSheen } from "@Models";
import { Paint } from "@Models";


export function mapToPaint(codeSet: PaintCodeSet, csvRow: MultiCsvFileItems) : Paint {
    const colour = Colour.parse(csvRow.h);
    const indexNmber = parseInt(csvRow.nc);
    var variantCodes = (isNullOrEmpty(csvRow.vc) ? csvRow.nc :csvRow.vc).split('|');
    const productCode = isNullOrEmpty(csvRow.pc) ? csvRow.nc : csvRow.pc;
    return new Paint(indexNmber, productCode, variantCodes, csvRow.d, colour, PaintSheen[csvRow.s], codeSet);
} 


export function mapMdxToArticle(mdx: Mdx) {
    return new Article(mdx.fields.route,
            mdx.frontmatter.title,
            mdx.frontmatter.standfirst,
            "James",
            new Date(mdx.frontmatter.date),
            mdx.frontmatter.mainImage,
            mdx.timeToRead);
}


export function mapMdxToHowTo(article: Article, node: Mdx) {
    const steps: HowToStep[] = (node.fields.steps || []).map(x => ({
        name: x.title,
        text: x.description,
        image: x.imageUrl
    }));
    return new HowToData(
      article,
      steps);
  }


export function mapToWebsiteInformation(siteMeta: SiteSiteMetadata) : WebsiteInformation {
    return new WebsiteInformation(
        siteMeta.name,
        siteMeta.siteUrl,
        siteMeta.siteLogoUrl,
        siteMeta.siteLogoUrlSquare,
        Array.from(mapMetadataToSocialSites(siteMeta.social))
    );
}

export function* mapMetadataToSocialSites(metaData: SiteSiteMetadataSocial) : Generator<ISocialPage> {
    if (metaData.twitter) {
        yield new TwitterPage(metaData.twitter.handle);
    }
}