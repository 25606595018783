import { MatchingFilters, Paint, PaintCodeSet } from "@Models";


export function createPaintMatchingUrl(sourcePaint: Paint, sourceCodeSet: PaintCodeSet, filters: MatchingFilters) {
    let url = `${sourceCodeSet.route}${encodeURIComponent(sourcePaint.productCode)}`;
    if (filters.singleCodeSetOnly) {
        url += `?filter=${encodeURIComponent(filters.singleCodeSetOnly.slug)}`;
        if (!filters.includeAlternateSheens) {
            url += `&sheen=restrict`;
        }
    } else if (!filters.includeAlternateSheens) {
        url += `?sheen=restrict`;
    }
    return url;
}