import { Colour, PaintSheen, Paint, PaintCodeSet, MatchingFilters, PaintMatchingResult, canMatchOtherSheens, ConversionChart, MatchStrengthBreakdown } from "@Models";
import * as DeltaE from 'delta-e';
import { isNullOrEmpty } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { roundDp } from "./roundDp";

const alwaysIncludeMatchesOverThisScore = 3.5;
const neverIncludeMatchesAboveThisDeltaE = 20;

export async function getMatches(source: { paint: Paint, filters : MatchingFilters, sourceCodeSet: PaintCodeSet }, data: { allCodeSets: PaintCodeSet[], charts: { [brandedCodeSet: string]: ConversionChart} } ) {
    const { filters, paint: sourcePaint, sourceCodeSet } = source;
    const { allCodeSets, charts } = data;
    let results: PaintMatchingResult[] = [];
    const codeSetsToMatch = (filters.singleCodeSetOnly ? [allCodeSets.find(x => x.slug === filters.singleCodeSetOnly.slug)] : allCodeSets.filter(codeSet => codeSet.slug != sourceCodeSet.slug)).filter(x => x.canBeMatchedTo);
    const chart: ConversionChart | undefined = charts[sourceCodeSet.fullName];
    const chartRow = chart ? chart.sourcePaints[sourcePaint.productCode] : undefined;
    for (let otherCodeSet of codeSetsToMatch) {
        let brandResults: PaintMatchingResult[] = [];
        let otherPaints = otherCodeSet.paints;
        otherPaints = filterToCompatibleSheens(sourcePaint, otherPaints, filters);
        if (otherPaints.length == 0) {
            continue;
        }
        let chartMatchResults: PaintMatchingResult[] = [];
        if (chartRow) {
            const chartMatchCodes = chartRow.matches.filter(x => x.codeSet === otherCodeSet.fullName).map(x => x.productCode);
            for(let chartMatchCode of chartMatchCodes.filter(x => !isNullOrEmpty(x))) {
                const chartPaint = otherPaints.find(x => x.productCode == chartMatchCode);
                if (!chartPaint) {
                    console.warn(`The chart match paint ${chartMatchCode} was not found in code set ${otherCodeSet.fullName}`);
                    continue;
                } else if (chartMatchResults.some(x => x.codeSet.fullName === otherCodeSet.fullName && x.paint.productCode === chartPaint.productCode)) {
                    console.warn(`Duplicate chart match found for ${chartMatchCode} in ${otherCodeSet.fullName} on row ${sourcePaint.productCode}`);
                    continue;
                }
                const deltaE = getDeltaE(sourcePaint.colour, chartPaint.colour);
                const breakdown = getMatchStrength(sourcePaint, chartPaint, deltaE, 1);
                const chartResult = new PaintMatchingResult(
                    otherCodeSet,
                    chartPaint,
                    breakdown.scoreOutOfFive,
                    breakdown,
                    roundDp(deltaE));
                chartMatchResults.push(chartResult);
                
                brandResults.push(chartResult);
            }
        }

        let bestColourMatchesForThisBrand = getDeltaEMatches(sourcePaint, otherPaints, otherCodeSet);
        const definitelyIncludeMatches = bestColourMatchesForThisBrand.filter(x => x.matchStrength > alwaysIncludeMatchesOverThisScore);
        const minMatchesPerBrand = filters.singleCodeSetOnly ? 5 : 2;
        const colourMatches = definitelyIncludeMatches.length < minMatchesPerBrand ? bestColourMatchesForThisBrand.slice(0, Math.min(bestColourMatchesForThisBrand.length, minMatchesPerBrand)) : definitelyIncludeMatches;

        // // Group the chart matches and returns them combined
        // const chartGroups = groupBy<PaintMatchingResult>(chartMatchResults, (x: PaintMatchingResult) => x.codeSet.fullName + x.paint.productCode);
        // for (let group of chartGroups) {
        //     const item = group.value[0];
        //     if (group.value.length > 1) {
        //         const newBreakdown = getMatchStrength(sourcePaint, item.paint, item.deltaE, group.value.length);
        //         item.breakdown = newBreakdown;
        //     }
        //     brandResults.push(item);
        // }

        //Then return the colour matches
        for (let x of colourMatches) {
            if (chartMatchResults.findIndex(z => z.paint.productCode == x.paint.productCode) < 0) {
                brandResults.push(x);
            }
        }
        brandResults.sort((a, b) => b.matchStrength - a.matchStrength);
        results.push(...brandResults);
    }
    return results;

}

function getDeltaE(colourA: Colour, colourB: Colour): number {
    return DeltaE.getDeltaE94(Colour.toCEILAB(colourA), Colour.toCEILAB(colourB));
}

function getMatchStrength(sourcePaint: Paint, otherPaint: Paint, deltaE: number, onCharts: number): MatchStrengthBreakdown {
    const maxDelta = 15;
    let normalisedDeltaE = deltaE < maxDelta ? (maxDelta - deltaE) / 3.3 : 0;
    const sheenModifier = getSheenScoreModifier(sourcePaint.sheen, otherPaint.sheen);
    const nameModifier = getNameScoreModifier(sourcePaint.description, otherPaint.description);
    const chartModifier = Math.min(onCharts, 2);
    return new MatchStrengthBreakdown(normalisedDeltaE, {s: sheenModifier, n: nameModifier, c: chartModifier});
}


function getDeltaEMatches(sourcePaint: Paint, otherBrandPaints: Paint[], otherCodeSet: PaintCodeSet): PaintMatchingResult[] {
    const colourMatches = otherBrandPaints.map(otherPaint => {
        const deltaE = getDeltaE(sourcePaint.colour, otherPaint.colour);
        const breakdown = getMatchStrength(sourcePaint, otherPaint, deltaE, 0);
        return new PaintMatchingResult(
            otherCodeSet,
            otherPaint,
            breakdown.scoreOutOfFive,
            breakdown,
            roundDp(deltaE));
    });

    colourMatches.sort((a, b) => b.matchStrength - a.matchStrength);
    return colourMatches.filter(x => x.deltaE <= neverIncludeMatchesAboveThisDeltaE);
}


function filterToCompatibleSheens(sourcePaint: Paint, paints: Paint[], filters: MatchingFilters) {
    // If the source paint is unknown sheen then allow anything
    if (sourcePaint.sheenAsComparable == PaintSheen.Unknown)
        return paints;

    // If the source paint is metallic or something, discard anything that isn't metallic
    if (!canMatchOtherSheens(sourcePaint.sheenAsComparable)) {
        return paints.filter(p => p.sheen == sourcePaint.sheen);
    }

    // If the source paint allows other sheen matches, check the filter
    if (!filters.includeAlternateSheens) {
        // Return exact sheens
        return paints.filter(p => p.sheen == sourcePaint.sheen);
    } else {
        // Return other paints that can also match other sheens
        return paints.filter(p => canMatchOtherSheens(p.sheenAsComparable));
    }
}

function getNameScoreModifier(descriptionA: string, descriptionB: string) {
    if (descriptionA === descriptionB && descriptionA.includes(' ')) return 1;

    const aSects = descriptionA.split(' ');
    const bSects = descriptionB.split(' ');
    if (aSects.some(x => bSects.some(y => y == x))) {
        return 0.5;
    } else {
        return 0;
    }
}

function getSheenScoreModifier(sheenA: PaintSheen, sheenB: PaintSheen) {
    if (sheenA == sheenB) {
        return 0.5;
    } else {
        return 0;
    }
}

