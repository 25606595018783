// @ts-check

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { Boot } from "./src/Boot"
import React from "react"
import { PageContextWrapper } from "./src/PageContextWrapper";
//import "./src/sass/structural.scss";

export const onClientEntry = () => {
  
}

export const onInitialClientRender = async () => {
  //TODO you can load stuff here if needs be (ie the client side search index)
  // console.log("ReactDOM.render has executed. Loading data file now");
  // const data = await fetch('/assets/data/pedals.csv');
  // const dataText = await data.text();
  // console.log(dataText);
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  // console.log('new pathname', location.pathname)
  // console.log('old pathname', prevLocation ? prevLocation.pathname : null)
}

export const wrapRootElement = Boot


export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <PageContextWrapper isBrowser={true} {...props}>{element}</PageContextWrapper>
}