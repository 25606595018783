import { Brand, PaintCodeSet } from "@Models";
import React, { useReducer } from 'react';

type State = {
    brands: Brand[];
    flattenedCodeSets: PaintCodeSet[];
    downloading: boolean;
    loadErrored?: string;
};


export enum PaintBrandsActionType {
    PaintDataLoaded = "PAINTS_LOADED",
    BeginDownloadingData = "BEGIN_DOWNLOADING_DATA",
    PaintLoadError = "PAINT_LOAD_ERROR",
}

interface IBeginDownloadingData {
    type: PaintBrandsActionType.BeginDownloadingData;
}


interface IPaintLoadError {
    type: PaintBrandsActionType.PaintLoadError;
    error: string;
}

export interface IPaintDataLoaded {
    type: PaintBrandsActionType.PaintDataLoaded;
    brands: Brand[];
    flattenedCodeSets: PaintCodeSet[];
    initiallySelectedCodeSetKey?: string;
}

type PaintBrandsAction = IPaintDataLoaded | IBeginDownloadingData | IPaintLoadError | PaintMatchingContainer.ISelectSourceCodeSet;

export const emptyState: State = {
    brands: [],
    flattenedCodeSets: [],
    downloading: true
};


type ContextType = State & {
    dispatch: React.Dispatch<PaintBrandsAction>
};

export const PaintBrandsContext = React.createContext<ContextType>({
    ...emptyState,
    dispatch: () => { }
});


function reducer (state: State, action: PaintBrandsAction): State {
    switch (action.type) {
        case PaintBrandsActionType.BeginDownloadingData:
            return { brands: [], flattenedCodeSets: [], downloading: true};
        case PaintBrandsActionType.PaintLoadError:
            return { brands: [], flattenedCodeSets: [], downloading: false, loadErrored: action.error};
        case PaintBrandsActionType.PaintDataLoaded:
            return { brands: action.brands, flattenedCodeSets: action.flattenedCodeSets, downloading: false};
        default:
            return state;
    }
}


export function PaintBrandsContextProvider(props: { children: JSX.Element[] | JSX.Element, initialState?: State }) {
    const [state, dispatch] = useReducer(reducer, props.initialState || emptyState);
    const value = { ...state, dispatch };
    return <PaintBrandsContext.Provider value={value}>{props.children}</PaintBrandsContext.Provider>;
}
