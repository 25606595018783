import { loadPreferredLanguage } from "@Functions";
import { EnglishWords, englishSpellings } from "@Models";
import React, { useReducer } from 'react';

type State = {
        /**
     * This is set by the home screen and is used to decide whether the Back arrow can do a navigate back or should redirect to home
     * It is for if people jump straight to a route, the arrow should take them home not back to Google
     */
    historyRoot?: string; //OBSOLETE!
    /**
     * This is for sub/phantom routes.  Hitting back will invoke this action instead of actually going back
     */
    backAction?: string; //OBSOLETE!!

    lang: EnglishWords;
    spellingKey: string;
};


export enum GlobalStateActionType {
    SetHistoryRoot = "SET_HISTORY_ROOT",
    SetBackAction = "SET_BACK_ACTION",
    ClearBackAction = "CLEAR_BACK_ACTION",
    SelectLanguage = "SELECT_LANGUAGE"
}

interface ISetHistoryRoot {
    type: GlobalStateActionType.SetHistoryRoot;
    historyRoot: string;
}

interface ISetBackAction {
    type: GlobalStateActionType.SetBackAction;
    backAction: string;
}

interface IClearBackAction {
    type: GlobalStateActionType.ClearBackAction;
}

interface ISelectLanguage {
    type: GlobalStateActionType.SelectLanguage;
    spellingKey: string
}

type GlobalStateAction = ISetHistoryRoot | ISetBackAction | IClearBackAction | ISelectLanguage;

export const emptyState: State = {
    spellingKey: 'us',
    lang: englishSpellings['us']
};


export function loadGlobalStateFromLocalStorage() {
    const spellingKey = loadPreferredLanguage() || 'us';
    return {
        spellingKey: spellingKey,
        lang: englishSpellings[spellingKey]
    }
}


type ContextType = State & {
    dispatch: React.Dispatch<GlobalStateAction>
};

export const GlobalStateContext = React.createContext<ContextType>({
    ...emptyState,
    dispatch: () => { }
});


function reducer (state: State, action: GlobalStateAction): State {
    switch (action.type) {
        case GlobalStateActionType.SetHistoryRoot:
            return { historyRoot: action.historyRoot, lang: state.lang, spellingKey: state.spellingKey };
        case GlobalStateActionType.SetBackAction:
            return { historyRoot: state.historyRoot, backAction: action.backAction, lang: state.lang, spellingKey: state.spellingKey };
        case GlobalStateActionType.ClearBackAction:
            return { historyRoot: state.historyRoot, backAction: undefined, lang: state.lang, spellingKey: state.spellingKey };
        case GlobalStateActionType.SelectLanguage:
            return { ...state, lang: englishSpellings[action.spellingKey], spellingKey: action.spellingKey };
        default:
            return state;
    }
}


export function GlobalStateContextProvider(props: { children: JSX.Element[] | JSX.Element, initialState?: State }) {
    const [state, dispatch] = useReducer(reducer, props.initialState || emptyState);
    const value = { ...state, dispatch };
    return <GlobalStateContext.Provider value={value}>{props.children}</GlobalStateContext.Provider>;
}
