export type EnglishWords = {
    colour: string;
    grey: string;
    bluTack: string;
    modelling: string,
    tld: string;
}

export const englishSpellings: { [key: string]: EnglishWords } = {
    'uk': {
        colour: 'colour',
        grey: 'grey',
        bluTack: 'Blu Tack',
        modelling: 'modelling',
        tld: '.co.uk'
    },
    'us': {
        colour: 'color',
        grey: 'gray',
        bluTack :'Sticky Tack',
        modelling: 'modeling',
        tld: '.com'
    }
};