/**
 * Make sure these match up with the PaintSheen.cs enum on the C# side!!!
 */
export enum PaintSheen {
    Unknown,
    Matt,
    Satin,
    Gloss,
    Metallic,
    Clear,
    Fluorescent,
}

/**
 * NOTE make sure you pass paint.sheenAsComparable here!
 */
export function canMatchOtherSheens(sheen: PaintSheen) {
    if (typeof sheen === 'string') throw new Error('Tried to compare a string sheen');
    switch (sheen) {
        case PaintSheen.Gloss:
        case PaintSheen.Matt:
        case PaintSheen.Satin:
            return true;
        default:
            return false;
    }
}