import { Paint, PaintCodeSet, PaintMatchingResult, MatchingFilters, ConversionChart } from "@Models";
import React, { useEffect, useReducer } from 'react';
import * as ReactGA from 'react-ga';
import * as Sentry from '@sentry/browser';
import { downloadAllSourcePaints, downloadCharts, getMatches } from "@Functions";

type State = {
    sourcePaint: Paint | null;
    filteredSourcePaints: Paint[];
    results: PaintMatchingResult[];
    filters: MatchingFilters;
    loadingResults: boolean;
    matchingError?: string;
    charts: { [brandedCodeSet: string]: ConversionChart };
    allCodeSetNames: string[];
    allSources: PaintCodeSet[];
};


export enum PaintMatchingActionType {
    LoadAllSourcesAndCharts = "LOAD_ALL_SOURCES_AND_CHARTS",
    SetAllCodeSetNames = "SET_ALL_CODE_SET_NAMES",
    MatchNow = "MATCH_NOW",
    SelectSourcePaint = "SELECT_SOURCE_PAINT",
    SourcePaintsFiltered = "SOURCE_PAINTS_FILTERED",
    ChangeResultFilters = "CHANGE_RESULT_FILTERS",
    MatchingResults = "MATCHING_RESULTS",
    ClearResults = "CLEAR_RESULTS",
    LoadSourcePaintList = "LOAD_SOURCE_PAINT_LIST",
    MatchingError = "MATCHING_ERROR"
}

interface MatchNowAction {
    type: PaintMatchingActionType.MatchNow,
    sourcePaint: Paint,
    filters: MatchingFilters
}

interface SetAllCodeSetNamesAction {
    type: PaintMatchingActionType.SetAllCodeSetNames,
    allCodeSetNames: string[]
}

interface ILoadAllSourcesAndCharts {
    type: PaintMatchingActionType.LoadAllSourcesAndCharts,
    allSources: PaintCodeSet[];
    charts: { [brandedCodeSet: string]: ConversionChart };
}


interface ISelectSourcePaint {
    type: PaintMatchingActionType.SelectSourcePaint,
    sourcePaint: Paint
}

interface ILoadSourcePaintList {
    type: PaintMatchingActionType.LoadSourcePaintList,
    allSourcePaints: Paint[];
}

interface IChangeResultFilters {
    type: PaintMatchingActionType.ChangeResultFilters,
    filters: MatchingFilters
}

interface ISourcePaintsFiltered {
    type: PaintMatchingActionType.SourcePaintsFiltered,
    results: Paint[]
}

interface IMatchingResults {
    type: PaintMatchingActionType.MatchingResults,
    results: PaintMatchingResult[]
}

interface IClearResults {
    type: PaintMatchingActionType.ClearResults,
    allSourcePaints: Paint[];
}

interface IMatchingError {
    type: PaintMatchingActionType.MatchingError,
    error: string
}

type PaintMatchingAction = MatchNowAction | ILoadSourcePaintList | SetAllCodeSetNamesAction | ISelectSourcePaint | IChangeResultFilters | ISourcePaintsFiltered | IMatchingResults | IClearResults | IMatchingError | ILoadAllSourcesAndCharts;

export const paintMatchingContextEmptyState: State = {
    sourcePaint: null,
    results: [],
    loadingResults: false,
    filteredSourcePaints: [],
    allCodeSetNames: [],
    allSources: [],
    charts: {},
    filters: {
        includeAlternateSheens: true
    }
};


type ContextType = State & {
    dispatch: React.Dispatch<PaintMatchingAction>
    matchPaint: (sourcePaint: Paint, filters: MatchingFilters, sourceCodeSet: PaintCodeSet) => Promise<void>
};

export const PaintMatchingContext = React.createContext<ContextType>({
    ...paintMatchingContextEmptyState,
    dispatch: () => { },
    matchPaint: async () => {}
});


function reducer(state: State, action: PaintMatchingAction): State {
    switch (action.type) {
        case PaintMatchingActionType.SetAllCodeSetNames: {
            return {
                ...state,
                allCodeSetNames: action.allCodeSetNames
            }
        }
        case PaintMatchingActionType.LoadSourcePaintList: {
            return {
                ...state,
                filteredSourcePaints: action.allSourcePaints
            }
        }
        case PaintMatchingActionType.LoadAllSourcesAndCharts: {
            return {
                ...state,
                allSources: action.allSources,
                charts: action.charts
            }
        }
        case PaintMatchingActionType.ClearResults:
            return {
                ...state,
                sourcePaint: null,
                results: [],
                loadingResults: false,
                filteredSourcePaints: action.allSourcePaints,
            };
        case PaintMatchingActionType.MatchNow:
            return {
                ...state,
                sourcePaint: action.sourcePaint,
                filters: action.filters,
                results: [],
                loadingResults: true
            };
        case PaintMatchingActionType.SelectSourcePaint:
            return {
                ...state,
                sourcePaint: action.sourcePaint,
                results: [],
                loadingResults: true
            };
        case PaintMatchingActionType.ChangeResultFilters:
            return {
                ...state,
                filters: action.filters,
                sourcePaint: state.sourcePaint,
                results: [],
                loadingResults: state.sourcePaint ? true : false,
                filteredSourcePaints: state.filteredSourcePaints
            };
        case PaintMatchingActionType.SourcePaintsFiltered:
            return {
                ...state,
                filteredSourcePaints: action.results
            };
        case PaintMatchingActionType.MatchingResults:
            return {
                ...state,
                results: action.results,
                loadingResults: false
            };
        case PaintMatchingActionType.MatchingError:
            return {
                ...state,
                matchingError: action.error
            };
        default:
            return state;
    }
}


/**
 * Provided by Gatsby, these are things that can change when the route changes and need updating in the context's internal store
 */
type ProviderProps = {
    children: JSX.Element[] | JSX.Element,
    initialState: State,
    allCodeSetNames: string[],
    allSourcePaints: Paint[] | null
    matchNow: {
        sourcePaint: Paint
        filters: MatchingFilters
        sourceCodeSet: PaintCodeSet
    } | null
}

export function PaintMatchingContextProvider(props: ProviderProps) {
    const [state, dispatch] = useReducer(reducer, props.initialState);



    function handleError(message: string, e?: { message: string }) {
        console.error(message);
        if (e) console.error(e);
        ReactGA.exception({
            description: message,
            fatal: true
        });
        Sentry.withScope(scope => {
            Sentry.captureException(e ? e : new Error(message));
        });
        dispatch({ type: PaintMatchingActionType.MatchingError, error: message });
    }

    async function matchPaint(sourcePaint: Paint, filters: MatchingFilters, sourceCodeSet: PaintCodeSet) {
        dispatch({ type: PaintMatchingActionType.MatchNow, sourcePaint: sourcePaint, filters: filters });
        try {
            let charts = state.charts;
            let allSources = state.allSources;
            if (!allSources.length) {
                //TODO you might want to put this into a promise and have both this and the useEffect above await the promise
                charts = await downloadCharts();
                allSources = await downloadAllSourcePaints(props.allCodeSetNames);
                dispatch({ type: PaintMatchingActionType.LoadAllSourcesAndCharts, allSources, charts });
            }

            let results = await getMatches({
                paint: sourcePaint,
                filters: filters,
                sourceCodeSet: sourceCodeSet
            },
                {
                    allCodeSets: allSources,
                    charts: charts
                });
            dispatch({ type: PaintMatchingActionType.MatchingResults, results });
        } catch (e) {
            debugger;
            handleError(`Error matching paint.  selectSourcePaint(${sourcePaint.getUniquePaintCode()}) ${e.message}`, e);
        }

    }

    const value = { ...state, dispatch, matchPaint };

    // Load in allCodeSetNames the first time we navigate to a route that contains them
    useEffect(() => {
        if (props.allCodeSetNames.length > 0) {
            dispatch({ type: PaintMatchingActionType.SetAllCodeSetNames, allCodeSetNames: props.allCodeSetNames })
        }
    }, [props.allCodeSetNames])

    // Load in the allSourcePaints list the first time we navigate to a route that contains a list of paints
    useEffect(() => {
        if (props.allSourcePaints && props.allSourcePaints.length > 0) {
            dispatch({ type: PaintMatchingActionType.LoadSourcePaintList, allSourcePaints: props.allSourcePaints })
        }
    }, [props.allSourcePaints])

    // Perform matching when we see the sourcePaint or filters change via the route
    useEffect(() => {
        if (props.matchNow) {
            matchPaint(props.matchNow.sourcePaint, props.matchNow.filters, props.matchNow.sourceCodeSet);
        } else {
            dispatch({ type: PaintMatchingActionType.ClearResults, allSourcePaints: props.allSourcePaints || props.initialState.filteredSourcePaints || [] })
        }
    }, [props.matchNow])




    return <PaintMatchingContext.Provider value={value}>{props.children}</PaintMatchingContext.Provider>;
}
