import React from "react"
import { Props as ConversionPageLayoutProps } from "./layouts/ConversionPageLayout";
import { mapToPaint } from "./graphql/graphql-mappers";
import { MatchingFilters, Paint, PaintCodeSet } from "@Models";
import { SourcePaintListContextProvider, sourcePaintListContextEmptyState, UserBasketContextProvider, paintMatchingContextEmptyState, PaintMatchingContextProvider, loadUserBasketStateFromLocalStorage, loadGlobalStateFromLocalStorage, GlobalStateContextProvider } from "@Contexts";
import { parse as parseQueryString } from 'query-string';
import { isNullOrEmpty } from "@jcharlesworthuk/your-mum-core/dist/functions";

type Props = ConversionPageLayoutProps & {
    path: string;
    isBrowser: boolean;
    children: JSX.Element[] | JSX.Element,
    location: {
        pathname: string,
        search: string
    },
}

type FilterQueryParams = {
    filter?: string;
    sheen?: 'restrict' | 'all'
}

export const PageContextWrapper: React.FunctionComponent<Props> = (props: Props) => {
    const sourcePaintListContextState = sourcePaintListContextEmptyState;
    const paintMatchingContextState = paintMatchingContextEmptyState;
    const userBasketContextState = loadUserBasketStateFromLocalStorage();
    const globalSettingsContextState = loadGlobalStateFromLocalStorage();

    //These are the context props we can load directly from the Gatsby data...
    let allCodeSetNames: string[] = [];
    let sourceCodeSet: PaintCodeSet | null = null;
    let matchNow: {
        filters: MatchingFilters;
        sourcePaint: Paint,
        sourceCodeSet: PaintCodeSet
    } | null = null;
    let allSourcePaints: Paint[] | null = null;

    if (props.data) {

        // If this page contains a full list of paint ranges
        if (props.data.paintListFileNames) {
            allCodeSetNames = props.data.paintListFileNames.edges.map(x => x.node.name);
            paintMatchingContextState.allCodeSetNames = allCodeSetNames;
        }

        // If this page is for a specific paint set (eg. Humbrol-General)
        if (props.pageContext.codeSetName && props.data.myFullPaintList) {
            sourceCodeSet = PaintCodeSet.parse(props.pageContext.codeSetName);
            const paints = props.data.myFullPaintList.edges[0].node.childMultiCsvFile.items.map(x => mapToPaint(sourceCodeSet, x));
            sourceCodeSet.paints.push(...paints);
            sourcePaintListContextState.sourceCodeSet = sourceCodeSet;
            paintMatchingContextState.filteredSourcePaints = paints;
            allSourcePaints = paints;

            // If this is a client-only route for a speicic paint
            const pathSections = props.location.pathname.split('/');
            if (pathSections.length > 3 && pathSections[1] === 'paint-conversion-chart' && !isNullOrEmpty(pathSections[3])) {
                const paintCode = pathSections[3];
                const matchingPaint = paints.find(x => x.productCode.toLowerCase() === paintCode.toLowerCase());
                if (matchingPaint) {
                    matchNow = {
                        sourcePaint: matchingPaint,
                        filters: paintMatchingContextState.filters,
                        sourceCodeSet: sourceCodeSet
                    };
                    paintMatchingContextState.sourcePaint = matchingPaint;
                }
            }

            // If there is a filter in the query string, implement it
            if (props.location.search) {
                const filterParams = parseQueryString(props.location.search) as FilterQueryParams;
                if (filterParams.filter) {
                    const filterToCodeSet = allCodeSetNames.find(x => x.toLowerCase() === filterParams.filter.toLowerCase());
                    const filters = {
                        includeAlternateSheens: filterParams.sheen === 'restrict' ? false : true,
                        singleCodeSetOnly: filterToCodeSet ? PaintCodeSet.parse(filterToCodeSet) : undefined
                    };
                    paintMatchingContextState.filters = filters;
                    if (matchNow) {
                        matchNow.filters = filters;
                    }
                }
            }
        }
    }

    return <div className="context-wrapper">
        <GlobalStateContextProvider initialState={globalSettingsContextState}>
            <UserBasketContextProvider initialState={userBasketContextState}>
                <SourcePaintListContextProvider initialState={sourcePaintListContextState} sourceCodeSet={sourceCodeSet}>
                    <PaintMatchingContextProvider initialState={paintMatchingContextState} allCodeSetNames={allCodeSetNames} matchNow={matchNow} allSourcePaints={allSourcePaints}>
                        {props.children}
                    </PaintMatchingContextProvider>
                </SourcePaintListContextProvider>
            </UserBasketContextProvider>
        </GlobalStateContextProvider>
    </div>
}

