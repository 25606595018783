import React from "react"
import * as Sentry from '@sentry/browser';
import { isRedirect } from "@reach/router"

export const Boot: React.FunctionComponent<{ element: any }> = ({ element }) => {
  return (
    <ErrorBoundary>
      {element}
    </ErrorBoundary>
  )
}

class ErrorBoundary extends React.Component<{ children: any }, { error: any | null }> {

  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    if (isRedirect(error)) {
      throw error
    } else {
      this.setState({ error });
      Sentry.configureScope((scope) => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
      });
      Sentry.captureException(error);
    }
  }

  render() {
    if (this.state.error) {
      return <h1>Oops! Something went wrong</h1>;
    } else {
      return <main>
        {this.props.children}
      </main>
    }
  }
}