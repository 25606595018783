import { Paint } from '@Models';
import { loadStoredBasket } from 'functions/userBasketStorage';
import React, { useReducer } from 'react';

type State = {
    myPaintList: Paint[];
};


export enum UserBasketActionType {
    AddPaintToMyList = "ADD_PAINT_TO_MY_LIST",
    RemovePaintFromMyList = "REMOVE_PAINT_FROM_MY_LIST",
}

interface IAddPaintToMyList {
    type: UserBasketActionType.AddPaintToMyList;
    item: Paint;
}

interface IRemovePaintFromMyList {
    type: UserBasketActionType.RemovePaintFromMyList;
    item: Paint;
}

type UserBasketAction = IAddPaintToMyList | IRemovePaintFromMyList;


export function loadUserBasketStateFromLocalStorage() {
    const paints = loadStoredBasket();
    return {
        myPaintList: paints || []
    };
}


type ContextType = State & {
    dispatch: React.Dispatch<UserBasketAction>
};

export const UserBasketContext = React.createContext<ContextType>({
    ...loadUserBasketStateFromLocalStorage(),
    dispatch: () => { }
});


function reducer (state: State, action: UserBasketAction): State {
    switch (action.type) {
            case UserBasketActionType.AddPaintToMyList:
                if (state.myPaintList.findIndex(x => x.getUniquePaintCode() == action.item.getUniquePaintCode()) >= 0) {
                    return state;
                }
                return {
                    myPaintList: [...state.myPaintList, action.item]
                }
            case UserBasketActionType.RemovePaintFromMyList:
                const deleteIndex = state.myPaintList.findIndex(x => x.getUniquePaintCode() == action.item.getUniquePaintCode());
                if (deleteIndex < 0) {
                    return state;
                }
                const cloneArray = [...state.myPaintList];
                cloneArray.splice(deleteIndex, 1);
                return {
                    myPaintList: cloneArray
                };

        default:
            return state;
    }
}


export function UserBasketContextProvider(props: { children: JSX.Element[] | JSX.Element, initialState?: State }) {
    const [state, dispatch] = useReducer(reducer, props.initialState || loadUserBasketStateFromLocalStorage());
    const value = { ...state, dispatch };
    return <UserBasketContext.Provider value={value}>{props.children}</UserBasketContext.Provider>;
}
