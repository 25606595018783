import { PaintCodeSet } from "@Models";
import React, { useEffect, useReducer } from 'react';

type State = {
    sourceCodeSet: PaintCodeSet;
};


export enum SourcePaintListActionType {
    ChangeSourceSet = "CHANGE_SOURCE_SET"
}


type SourcePaintListAction = {
    type: SourcePaintListActionType;
    sourceCodeSet: PaintCodeSet;
};

export const sourcePaintListContextEmptyState: State = {
    sourceCodeSet: new PaintCodeSet('INVALID', 'INVALID', [])
};


type ContextType = State & {
    dispatch: React.Dispatch<SourcePaintListAction>
};

export const SourcePaintListContext = React.createContext<ContextType>({
    ...sourcePaintListContextEmptyState,
    dispatch: () => { }
});


function reducer (state: State, action: SourcePaintListAction): State {
    switch (action.type) {
        case SourcePaintListActionType.ChangeSourceSet:
            return {
                sourceCodeSet: action.sourceCodeSet
            }
        default:
            return state;
    }
}


/**
 * Provided by Gatsby, these are things that can change when the route changes and need updating in the context's internal store
 */
type ProviderProps = {
    children: JSX.Element[] | JSX.Element, 
    initialState: State,
    sourceCodeSet: PaintCodeSet | null
}

export function SourcePaintListContextProvider(props: ProviderProps) {
    const [state, dispatch] = useReducer(reducer, props.initialState);
    const value = { ...state, dispatch };

    useEffect(() => {
        dispatch({ type: SourcePaintListActionType.ChangeSourceSet, sourceCodeSet: props.sourceCodeSet || sourcePaintListContextEmptyState.sourceCodeSet})
    }, [props.sourceCodeSet])

    return <SourcePaintListContext.Provider value={value}>{props.children}</SourcePaintListContext.Provider>;
}
