import { PaintCodeSet, Paint } from "@Models";
import { MatchStrengthBreakdown } from "./MatchStrengthBreakdown";

export class PaintMatchingResult {
    constructor(public codeSet: PaintCodeSet,
        public paint: Paint,
        public matchStrength: number,
        public breakdown: MatchStrengthBreakdown,
        public deltaE: number) {
    }

    public getUniquePaintCode() {
        return this.codeSet.slug + ':' + this.paint.productCode;
    }
}
