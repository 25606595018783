import { CEILAB } from 'delta-e';

export class Colour {

    constructor(public hex: string, public l: number, public a: number, public b: number) {
    }

    public static toCEILAB(colour: Colour): CEILAB {
        return {
            L: colour.l,
            A: colour.a,
            B: colour.b
        };
    }

    public static rgbToLab(r: number, g: number, b: number): number[] {
        var r = r / 255,
            g = g / 255,
            b = b / 255,
            x, y, z;

        r = (r > 0.04045) ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92;
        g = (g > 0.04045) ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92;
        b = (b > 0.04045) ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92;

        x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
        y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.00000;
        z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;

        x = (x > 0.008856) ? Math.pow(x, 1 / 3) : (7.787 * x) + 16 / 116;
        y = (y > 0.008856) ? Math.pow(y, 1 / 3) : (7.787 * y) + 16 / 116;
        z = (z > 0.008856) ? Math.pow(z, 1 / 3) : (7.787 * z) + 16 / 116;

        return [(116 * y) - 16, 500 * (x - y), 200 * (y - z)]
    }


    public static componentToHex(c: number) {
        if (c < 0) c = 0;
        var hex = c.toString(16);
        return hex.length == 1 ? '0' + hex : hex;
    }

    public static rgbToHex(r: number, g: number, b: number) {
        return "#" + Colour.componentToHex(r) + Colour.componentToHex(g) + Colour.componentToHex(b);
    }

    public static hexToRgb(hex: string): number[] {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        if (!result) {
            console.error('Invalid colour hex string ' + hex);
            //TODO throw this
            return [0, 0, 0];
        }

        return [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)];
    }

    public static parse(hex: string): Colour {
        const rgb = Colour.hexToRgb(hex);
        const lab = Colour.rgbToLab(rgb[0], rgb[1], rgb[2]);
        const hexWithPrefix = hex.startsWith('#') ? hex : '#' + hex;
        return new Colour(hexWithPrefix, roundNicely(lab[0]), roundNicely(lab[1]), roundNicely(lab[2]));
    }
}

export function roundNicely(d: number) {
    return Math.round(d * 100) / 100;
}
