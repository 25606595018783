import { Colour } from "./Colour";
import { PaintSheen } from "./PaintSheen";
import { PaintCodeSet } from "./PaintCodeSet";
import { PaintDto } from "./PaintDto";

export class Paint {

    constructor(public indexNumber: number,
        public productCode: string,
        public variantCodes: string[],
        public description: string,
        public colour: Colour,
        public sheen: PaintSheen,
        public codeSet?: PaintCodeSet) {
    }

    get sheenAsComparable() {
        const compareAs: PaintSheen = typeof this.sheen === 'string' ? PaintSheen[this.sheen as string] : this.sheen;
        return compareAs;
    }

    public getUniquePaintCode() {
        if (!this.codeSet) {
            throw new Error('Cannot get unique paint code, no code set linked to paint');            
        }
        return this.codeSet.brandNameSlug + ':' + this.productCode;
    }

    toDto():PaintDto {
        if (!this.codeSet) throw new Error('Cannot convert to DTO with no codeset set');
        return {
            indexNumber: this.indexNumber,
            productCode: this.productCode,
            variantCodes: this.variantCodes,
            description: this.description,
            hex: this.colour.hex,
            sheen: this.sheen.toString(),
            codeSet: this.codeSet!.toDto()
        };
    }

    static fromDto(dto: PaintDto): Paint {
        const colour = Colour.parse(dto.hex);
        return new Paint(dto.indexNumber, dto.productCode, dto.variantCodes, dto.description, colour, PaintSheen[dto.sheen], PaintCodeSet.fromDto(dto.codeSet));
    }
}